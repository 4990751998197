import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'next/navigation';
import { Product } from 'shared/types/product/Product';
import {
  PDP_PRODUCT_ADDED_TO_CART,
  QUICK_VIEW_PRODUCT_ADDED_TO_CART,
  PDP_VIEWED,
  PDP_VIEWED_AFTER_SEARCH,
  QUICK_VIEW_PRODUCT_ADDED_TO_CART_AFTER_SEARCH,
  PDP_PRODUCT_ADDED_TO_CART_AFTER_SEARCH,
  PDP_PRODUCT_CONTACT_DIVESHOP,
  PDP_PRODUCT_CONTACT_DIVESHOP_AFTER_SEARCH,
  QUICK_VIEW_PRODUCT_CONTACT_DIVESHOP,
  QUICK_VIEW_PRODUCT_CONTACT_DIVESHOP_AFTER_SEARCH,
  EVENT_ITEM_CATEGORY_BUNDLES,
  EVENT_ITEM_CATEGORY_COURSES,
  EVENT_ITEM_CATEGORY_CERT_CARDS,
  EVENT_ITEM_CATEGORY_CLUB,
  EVENT_ITEM_CATEGORY_DONATION,
  DEFAULT_CURRENCY,
  PADI_CHECKOUT_NAME,
  PADI_BRAND,
} from 'helpers/constants/events';

interface TrackingItem {
  item_id: string;
  item_name: string;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_category3: string;
  item_category4: string;
  item_category5: string;
  bundle_name: string;
  is_bundle: boolean;
  price: number;
  quantity: number;
}

interface Options {
  product: Product;
  inModalVersion?: boolean;
}

const useTrack = ({ product, inModalVersion }: Options) => {
  const searchParams = useSearchParams();
  const isAfterSearch = searchParams.get('sr');
  const lastProductId = useRef<string>(undefined);

  // Helper to safely fetch attribute values with a default value
  const getAttributeValue = (attr: any, defaultValue = '') => {
    if (Array.isArray(attr)) return attr[0]?.label ?? defaultValue;
    else return attr?.label ?? defaultValue;
  };

  // Helper to create a TrackingItem from a Product
  const createTrackingItem = useCallback((product: Product, count?: number): TrackingItem => {
    const variant = product.variants[0];
    const itemCount = count ?? 1;
    const bundleProducts = variant.attributes?.['products'];
    const isBundle = Array.isArray(bundleProducts) && bundleProducts.length > 1;
    const bundleSku = isBundle ? variant?.sku ?? '' : '';
    const bundleName = isBundle ? product?.name ?? '' : '';
    const sku = product.variants[0].sku;
    const price = variant.price?.centAmount ? variant.price?.centAmount / 100 : 0;
    const productType = product.productType;

    const data = {
      item_id: product.productId ?? '',
      item_name: product.name ?? '',
      item_brand: PADI_BRAND,
      item_category: '',
      item_category2: '',
      item_category3: '',
      item_category4: '',
      item_category5: '',
      bundle_name: '',
      is_bundle: false,
      price: price,
      quantity: itemCount,
    };

    switch (productType) {
      case 'bundle-parent':
        data.item_category = EVENT_ITEM_CATEGORY_BUNDLES;
        data.item_category5 = bundleSku;
        data.bundle_name = bundleName;
        data.is_bundle = isBundle;
        break;
      case 'Course':
        data.item_category = EVENT_ITEM_CATEGORY_COURSES;
        data.item_category2 = getAttributeValue(variant.attributes?.['experience-level']);
        data.item_category3 = getAttributeValue(variant.attributes?.['course-type']);
        data.item_category4 = getAttributeValue(variant.attributes?.['discipline']);
        break;
      case 'certification-card':
        data.item_category = EVENT_ITEM_CATEGORY_CERT_CARDS;
        break;
      case 'donation':
        data.item_category = EVENT_ITEM_CATEGORY_DONATION;
        break;
      case 'subscription':
        if (sku === 'MPC40955-1B2C') data.item_category = EVENT_ITEM_CATEGORY_CLUB;
        break;
      default:
        break;
    }

    return data;
  }, []);

  const trackView = useCallback(async () => {
    if (product && product.productId !== lastProductId.current) {
      const trackingItem = createTrackingItem(product);
      const variant = product.variants[0];

      gtag('event', isAfterSearch ? PDP_VIEWED_AFTER_SEARCH : PDP_VIEWED, {
        padi_checkout_name: PADI_CHECKOUT_NAME,
        currency: DEFAULT_CURRENCY,
        value: variant.price?.centAmount ? variant.price.centAmount / 100 : 0,
        items: [trackingItem],
      });

      lastProductId.current = product.productId;
    }
  }, [product, createTrackingItem, isAfterSearch]);

  useEffect(() => {
    trackView();
  }, [trackView]);

  const trackAddToCart = useCallback(
    async (count = 1) => {
      const trackingItem = createTrackingItem(product, count);
      const variant = product.variants[0];
      const itemCount = count ?? 1;
      const totalValue = itemCount * (variant.price?.centAmount ? variant.price.centAmount / 100 : 0);

      const eventName = inModalVersion
        ? isAfterSearch
          ? QUICK_VIEW_PRODUCT_ADDED_TO_CART_AFTER_SEARCH
          : QUICK_VIEW_PRODUCT_ADDED_TO_CART
        : isAfterSearch
        ? PDP_PRODUCT_ADDED_TO_CART_AFTER_SEARCH
        : PDP_PRODUCT_ADDED_TO_CART;

      gtag('event', eventName, {
        padi_checkout_name: PADI_CHECKOUT_NAME,
        currency: variant.price?.currencyCode ?? DEFAULT_CURRENCY,
        value: totalValue,
        items: [trackingItem],
      });
    },
    [product, isAfterSearch, inModalVersion],
  );

  const trackContactDiveshop = useCallback(
    async (count = 1) => {
      const trackingItem = createTrackingItem(product, count);
      const variant = product.variants[0];
      const itemCount = count ?? 1;
      const totalValue = itemCount * (variant.price?.centAmount ? variant.price.centAmount / 100 : 0);

      const eventName = inModalVersion
        ? isAfterSearch
          ? QUICK_VIEW_PRODUCT_CONTACT_DIVESHOP_AFTER_SEARCH
          : QUICK_VIEW_PRODUCT_CONTACT_DIVESHOP
        : isAfterSearch
        ? PDP_PRODUCT_CONTACT_DIVESHOP_AFTER_SEARCH
        : PDP_PRODUCT_CONTACT_DIVESHOP;

      gtag('event', eventName, {
        padi_checkout_name: PADI_CHECKOUT_NAME,
        currency: variant.price?.currencyCode ?? DEFAULT_CURRENCY,
        value: totalValue,
        items: [trackingItem],
      });
    },
    [product, isAfterSearch, inModalVersion],
  );

  return { trackAddToCart, trackContactDiveshop };
};

export default useTrack;
